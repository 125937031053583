var url = process.env.APP_ENDPOINT ? process.env.APP_ENDPOINT : '';
var appid = process.env.APP_ACCOUNT_APP_ID ? process.env.APP_ACCOUNT_APP_ID : '';

if (url === '' || appid === '')
{
    switch (window.location.host) {
        case "stg.store.xpertme.com":
            url = "https://api.stg.store.xpertme.com/"
            appid = "APPID=8"
            break;
        case "beta.store.xpertme.com":
            url = "https://api-store.xpertme.com/"
            appid = "APPID=15"
            break;
        case "store.xpertme.com":
            url = "https://api-store.xpertme.com/"
            appid = "APPID=4"
            break;
        case "dev.store.xpertme.com":
            url = "https://api.stg.store.xpertme.com/"
            appid = "APPID=13"
            break;
        case "store.xpert.ngrok.io":
            url = "https://api.stg.store.xpertme.com/"
            appid = "APPID=14"
            break;
        case "stg.store.xpertme.com":
            url = "https://api.stg.store.xpertme.com/"
            appid = "APPID=16"
            break;
        case "0c80247fb9be411ebe9ec97133db3840.vfs.cloud9.us-east-1.amazonaws.com":
            url = "https://api.stg.store.xpertme.com/"
            appid = "APPID=17"
            break;
        default:
            // url = "https://api.stg.store.xpertme.com/"
            url = "https://api.stg.store.xpertme.com/"
            appid = "APPID=13"
            break;

    }
}

export { appid }
export default url