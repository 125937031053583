import React from "react";
import assetIconFacebook from "../img/icons/facebook.png";
import assetIconLinkedIn from "../img/icons/linkedin.png";
import assetIconInstagram from "./../img/icons/instagram.png";
import assetIconTwitter from "../img/icons/twitter.png";
import assetIconYoutube from "../img/icons/youtube.png";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTwitter, faInstagram, faLinkedinIn, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
// import Logo from "../img/X-xpertcad3.png";

class foter extends React.Component{
    render(){
        return(
            // <footer className="page-footer grey darken-4 center-align white-text sp">
            //     <div className="footer-copyright">
            //         <div className="w-83 m-auto">
            //             <div className="row mn centrar">
            //                 <div className="col l4 s12 centrar">
            //                     <div className="row text-center m-auto">
            //                         <div className="col s3 l3">
            //                             <a href="https://www.facebook.com/pg/xpertcad/about/?ref=page_internal" target='blank' className="white-text">
            //                                 <FontAwesomeIcon icon={faFacebookSquare} className="fa-2x "/>
            //                             </a>
            //                         </div>
            //                         <div className="col s3 l3">
            //                             <a href="https://twitter.com/xpertcad" target="blank" className="white-text">
            //                                 <FontAwesomeIcon icon={faTwitter} className="fa-2x ml-10"/>
            //                             </a>
            //                         </div>
            //                         <div className="col s3 l3">
            //                             <a href="https://www.instagram.com/accounts/login/?next=/xpertcad/" target="blank" className="white-text">
            //                                 <FontAwesomeIcon icon={faInstagram} className="fa-2x ml-10"/>
            //                             </a>
            //                         </div>
            //                         <div className="col s3 l3">
            //                             <a href="https://www.linkedin.com/showcase/xpertcad/" target="blank" className="white-text">
            //                                 <FontAwesomeIcon icon={faLinkedinIn} className="fa-2x ml-10"/>
            //                             </a>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="col l4 s12 text-center centrar">
            //                     <h6 className="m-auto white-text">XpertCAD® 2020. All Rights Reserved.</h6>
            //                 </div>
            //                 <div className="col l4 s12 centrar">
            //                     <img src={Logo} alt="logo" className="w-50 m-auto"/>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </footer>
            // <div className="section full-height amber darken-4 hide-on-med-and-down" style="padding-top: 15px; padding-bottom: 15px;">
            <div>
                <div className="full-height amber darken-4 redes">
                    <div className=" container text-right h-100 centrar">
                        <a href=" https://www.facebook.com/xpertcad/" target="_blank " className="link-redes-foter ml-auto-md">
                            <img className="responsive-img " src={assetIconFacebook} alt="facebook"/>
                        </a>
                        <a href="https://www.linkedin.com/showcase/xpertcad/ " target="_blank " className="link-redes-foter">
                            <img className="responsive-img " src={assetIconLinkedIn} alt="linkedin"/>
                        </a>
                        <a href=" https://www.instagram.com/xpertcad/?hl=es-la" target="_blank " className="link-redes-foter">
                            <img className="responsive-img " src={assetIconInstagram} alt="instagram"/>
                        </a>
                        <a href=" https://twitter.com/xpertcad?lang=es" target="_blank " className="link-redes-foter">
                            <img className="responsive-img " src={assetIconTwitter} alt="twitter"/>
                        </a>
                        <a href=" https://www.youtube.com/channel/UCaK6vkXQrjdItaTwIn0t1CA" target="_blank " className="link-redes-foter">
                            <img className="responsive-img " src={assetIconYoutube} alt="youtube"/>
                        </a>
                    </div>
                </div>
                <footer className="page-footer orange darken-4 ">
                    <div className="container hide">
                        <div className="row ">
                            <div className="col s6 m2 ">
                                <h5 className="white-text foter-title">Xpertcad Crea</h5>
                                <ul>
                                    <li><a href=" https://xpertme.com/Crea.html " className="white-text ">Para Usuarios</a></li>
                                    <li><a href=" https://xpertme.com/Crea.html " className="white-text ">Para Evaluación
                            </a></li>
                                    <li><a href="https://xpertme.com/Crea.html " className="white-text ">Para Docentes
                            </a></li>
                                    <li><a href="https://xpertme.com/Crea.html " className="white-text ">Para Consultores
                            </a></li>
                                    <li><a href="https://xpertme.com/Crea.html " className="white-text ">Para Mentores
                            </a></li>
                                    <li><a href="https://xpertme.com/Crea.html " className="white-text ">Para Empresas
                            </a></li>
                                    <li><a href="https://xpertme.com/Crea.html " className="white-text ">Para Escuelas</a></li>
                                </ul>
                            </div>
                            <div className="col s6 m3 ">
                                <h5 className="white-text foter-title">Xpertcad Servicios
                                </h5>
                                <ul>
                                    <li><a href="https://xpertme.com/Servicios.html " className="white-text ">Experiencias de formación especializadas
                                </a></li>
                                    <li><a href="https://xpertme.com/Servicios.html " className="white-text ">Desarrollo de proyectos o experiencias en realidad aumentada y realidad virtual
                                </a></li>
                                    <li><a href="https://xpertme.com/Servicios.html " className="white-text ">Simuladores de aprendizaje virtual 
                                </a></li>
                                    <li><a href="https://xpertme.com/Servicios.html " className="white-text ">Desarrollo de experiencias de aprendizaje con conectividad IIoT

                                </a></li>
                                </ul>
                            </div>
                            <div className="col s6 m3 ">
                                <h5 className="white-text foter-title">Xpertcad Industria 4.0

                                </h5>
                                <ul>
                                    <li><a href="https://xpertme.com/Industria4.0.html " className="white-text ">Modelado 3D
                                </a></li>
                                    <li><a href=" https://xpertme.com/Industria4.0.html " className="white-text ">Simulación
                                </a></li>
                                    <li><a href=" https://xpertme.com/Industria4.0.html " className="white-text ">Manufactura Aditiva
                                </a></li>
                                    <li><a href="https://xpertme.com/Industria4.0.html " className="white-text ">Manufactura sustractiva
                                </a></li>
                                    <li><a href=" https://xpertme.com/Industria4.0.html " className="white-text ">Virtualización de procesos

                                </a></li>
                                    <li><a href="https://xpertme.com/Industria4.0.html " className="white-text ">Realidad Aumentada

                                </a></li>
                                    <li><a href="https://xpertme.com/Industria4.0.html " className="white-text ">Realidad Virtual

                                </a></li>
                                    <li><a href=" https://xpertme.com/Industria4.0.html " className="white-text ">Internet Industrial de las Cosas

                                </a></li>


                                </ul>
                            </div>
                            <div className="col s6 m2 ">
                                <h5 className="white-text foter-title">Xpertcad Store

                                </h5>
                                <ul>
                                    <li><a href=" https://store.xpertme.com/" className="white-text ">Modelado 3D
                                </a></li>
                                    <li><a href="https://store.xpertme.com/ " className="white-text ">Simulación
                                </a></li>
                                    <li><a href="https://store.xpertme.com/ " className="white-text ">Manufactura Aditiva
                                </a></li>
                                    <li><a href=" https://store.xpertme.com/" className="white-text ">Manufactura sustractiva
                                </a></li>
                                    <li><a href="https://store.xpertme.com/ " className="white-text ">Virtualización de procesos

                                </a></li>
                                    <li><a href="https://store.xpertme.com/ " className="white-text ">Realidad Aumentada

                                </a></li>
                                    <li><a href="https://store.xpertme.com/ " className="white-text ">Realidad Virtual

                                </a></li>
                                    <li><a href="https://store.xpertme.com/ " className="white-text ">Internet Industrial de las Cosas

                                </a></li>


                                </ul>
                            </div>
                            <div className="col s6 m2 ">
                                <h5 className="white-text foter-title">Contacto</h5>
                                <ul>
                                    <li>
                                        <a target="_blank " className="white-text " href="https://xpertme.com/Contacto.html">Soporte Tecnico</a>
                                    </li>
                                    <li>
                                        <a target="_blank " className="white-text " href="https://xpertme.com/Contacto.html" id="container1">Ventas</a>
                                    </li>
                                    <li><a target="_blank " className="white-text " href="https://api.whatsapp.com/send?phone=521 442 114 5914&text=Hola!%20Quiero%20contactarme%20con%20ustedes! ">Whatsapp</a></li>

                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="footer-copyright ">
                        <div className="container ">
                            <div className="left">
                                ©2009-2020 XpertCAD<sup>&#174; </sup>Todos los derechos reservados
                            </div>
                            <div className="right">
                                <div className="row">
                                    <div className="col s6">
                                        <a href="/#" className="white-text hide">Aviso de privacidad</a>
                                    </div>
                                    <div className="col s6" >
                                        <a href="/#" className="white-text hide">Terminos de uso</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}
export default foter