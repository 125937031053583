import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useEffect} from "react";
import M from 'materialize-css';
import CardComponent from "../Others/CardComponet";
import assetBanner from "../../img/banner-js.png";
export default function BannerPlan(params) {
   
    return (
       
     <div className="container">
        <div className="row p-40 r-m">
            <div className="col s12 m12 l5 xl5 center">
               <img src={assetBanner} alt="boohoo" className="responsive-img center" width="400"/>
            </div>
            <div className="col s12 m12 l7 xl7" >
                    <h1 className="text-black"><b className="b-text">Agrega valor</b> a tu perfil profesional a través del <b className="b-text">conocimiento especializado.</b></h1>
                    <h4 className="fw-500 grey-text text-darken-2">Aprende a la velocidad que necesitas, a través de una propuesta dinámica y atractiva de formación línea.</h4>
                    <a href="https://academy.xpertme.com/dashboard" target="_blank" rel="noopener noreferrer" className="btn-large orange">REGISTRATE GRATIS</a>
            </div>
        </div>
      </div>
    )
}